import React from "react";

const NavArrow: React.FC = () => {
  return (
    <svg
      width="31"
      height="28"
      viewBox="0 0 31 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4714 1.13807C14.7318 0.877722 14.7318 0.455612 14.4714 0.195262C14.2111 -0.0650874 13.789 -0.0650874 13.5286 0.195262L1.13809 12.5858C0.357044 13.3668 0.357047 14.6332 1.13809 15.4142L13.5286 27.8047C13.789 28.0651 14.2111 28.0651 14.4714 27.8047C14.7318 27.5444 14.7318 27.1223 14.4714 26.8619L2.27617 14.6667H30C30.3682 14.6667 30.6667 14.3682 30.6667 14C30.6667 13.6318 30.3682 13.3333 30 13.3333H2.27617L14.4714 1.13807Z"
        fill="#16435F"
      />
    </svg>
  );
};

export default NavArrow;
