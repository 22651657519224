import React from "react";
import styled from "styled-components";
import { Table } from "../../types/CookiesPage";

const StyledTable = styled.table`
  width: 100%;
  font-size: 15px;
  line-height: 20px;
  margin: 40px 0;
  text-align: left;
  @media (max-width: 1024px) {
    width: 100%;
    font-size: 12px;
  }
`;

const Thead = styled.thead`
  color: #16435f;
`;

const Tbody = styled.tbody`
  color: #808080;
`;

const Row = styled.tr``;

const Column = styled.td`
  font-size: 14px;
  line-height: 17px;
  padding: 10px 0;
`;

type TableProps = {
  name?: string;
  service?: string;
  purpose?: string;
  table?: Table[];
};

const CookieTable: React.FC<TableProps> = ({
  name,
  service,
  purpose,
  table,
}) => {
  return (
    <StyledTable>
      <Thead>
        <Row>
          <Column width={20}>{service}</Column>
          <Column width={20}>{name}</Column>
          <Column width={60}>{purpose}</Column>
        </Row>
      </Thead>
      <Tbody>
        {table?.map((item, i) => (
          <Row key={i}>
            <Column>{item.service}</Column>
            <Column>{item.name}</Column>
            <Column>{item.purpose?.purpose}</Column>
          </Row>
        ))}
      </Tbody>
    </StyledTable>
  );
};

export default CookieTable;
