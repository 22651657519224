import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { DropDownIcon } from "../LanguageSwitch/DropDownIcon";
import { contentfulPaginaCookie } from "../../types/CookiesPage";

const Wrapper = styled.div`
  width: 100%;
`;

const Content = styled.div`
  border: 1px solid #cccccc;
  background: #ffffff;
  z-index: 100;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 8px 16px;
`;

const ActiveLang = styled.label`
  color: #16435f;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  padding: 10px;
`;

const DropDownButton = styled.div`
  margin: 0 5px 0 0;
  padding: 0;
  display: flex;
  align-items: center;
`;

const Option = styled.a`
  padding: 8px;
  color: #16435f;
  text-decoration: none;
  border-radius: 5px;
  &:active,
  &:hover {
    font-weight: bold;
    background-color: rgba(204, 204, 204, 0.5);
  }
`;

const Select = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border: solid 1px #16435f;
  border-radius: 6px;
`;

interface DropDownProps {
  navItems?: contentfulPaginaCookie;
}

export const DropDownMenu: React.FC<DropDownProps> = ({ navItems }) => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | undefined>();

  React.useEffect(() => {
    setSelectedOption(
      navItems ? (navItems?.paragraph ? navItems.paragraph[0].name : "") : ""
    );
  }, []);

  return (
    <Wrapper>
      <Select
        onClick={() => {
          setOpen(!open);
        }}
      >
        <ActiveLang
          onClick={() => {
            setOpen(!open);
          }}
        >
          {selectedOption}
        </ActiveLang>
        <DropDownButton>
          <DropDownIcon fill="#16435f" open={open} />
        </DropDownButton>
      </Select>
      {open && (
        <Content>
          {navItems?.paragraph?.map((option, key) => (
            <Fragment key={key}>
              {option.name ? (
                <Option
                  href={`#${option.link}`}
                  onClick={() => {
                    setOpen(false);
                    setSelectedOption(option?.name);
                  }}
                >
                  {option?.name}
                </Option>
              ) : (
                ""
              )}
            </Fragment>
          ))}
        </Content>
      )}
    </Wrapper>
  );
};
