import React from "react";
import { Main } from "../layout/main";
import Container from "../components/Container";
import SideBar from "../components/CookiePage/SideBar";
import styled from "styled-components";
import { useIntl } from "gatsby-plugin-intl";
import { DropDownMenu } from "../components/CookiePage/DropDownMenu";
import CookieTable from "../components/CookiePage/CookieTable";
import { Title } from "../components/Typography";
import NavArrow from "../components/CookiePage/NavArrow";
import { graphql, Link } from "gatsby";
import { contentfulPaginaCookie } from "../types/CookiesPage";
import { contentfulTextualContent } from "../types/TextualContents";

const Content = styled.div`
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const PageHead = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const TitleWrapper = styled.div`
  flex: 3;
  @media (max-width: 1024px) {
    flex: 1;
  }
`;

const ArrowWrapper = styled.div`
  flex: 1.5;
  align-items: center;
  justify-content: start;
  display: flex;
`;

const RightPage = styled.div`
  flex: 3;
  margin-bottom: 100px;
  @media (max-width: 1024px) {
    flex: 1;
  }
`;

const LeftPage = styled.div`
  flex: 1.5;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const MenuWrapper = styled.div`
  display: none;
  @media (max-width: 1024px) {
    background-color: #ffffff;
    display: initial;
    z-index: 200;
    position: sticky;
    top: 75px;
    padding: 20px 0;
  }
`;

const TextSection = styled.div`
  padding: 160px 0 20px 0;
  margin-top: -160px;
`;

const CookieTitle = styled.h4`
  font-size: 32px;
  color: #16455f;
  margin: 0;
  padding: 0 0 30px 0;
  justify-content: center;
`;

const CookieSubTitle = styled.h5`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin: 20px 0;
  padding: 10px 0;
  color: #16435f;
`;

const CookieDescription = styled.div<React.HTMLAttributes<HTMLTitleElement>>`
  font-size: 20px;
  line-height: 24px;
  color: #808080;
  padding: 10px 0;
  & > a {
    color: #46b4fb;
    word-break: break-all;
  }
  & > h5 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin: 20px 0;
    padding: 10px 0;
    color: #16435f;
  }
`;

type PageProps = {
  data?: {
    contentfulPaginaCookie: contentfulPaginaCookie;
    contentfulTextualContent: contentfulTextualContent;
  };
};

const IndexPage: React.FC<PageProps> = ({ data }) => {
  const paragraphCookiePage = data?.contentfulPaginaCookie;
  const textContent = data?.contentfulTextualContent;
  const intl = useIntl();
  return (
    <Main showMenu={false}>
      <Container>
        <PageHead>
          <ArrowWrapper>
            <Link
              to={intl.defaultLocale === intl.locale ? "/" : `/${intl.locale}/`}
            >
              <NavArrow />
            </Link>
          </ArrowWrapper>
          <TitleWrapper>
            <Title>{textContent?.cookieTitle}</Title>
          </TitleWrapper>
        </PageHead>
        <Content>
          <MenuWrapper>
            <DropDownMenu navItems={paragraphCookiePage} />
          </MenuWrapper>
          <LeftPage>
            <SideBar navItems={paragraphCookiePage} />
          </LeftPage>
          <RightPage>
            {paragraphCookiePage?.paragraph?.map((item, i) => (
              <TextSection id={item?.link} key={i}>
                {item.name ? <CookieTitle>{item?.name}</CookieTitle> : ""}
                <CookieDescription
                  dangerouslySetInnerHTML={{
                    __html: `${
                      item?.description?.description
                        ? item?.description?.description
                        : ""
                    }`,
                  }}
                />
                {item.table ? (
                  <CookieTable
                    service={textContent?.cookieTableService}
                    name={textContent?.cookieTableName}
                    purpose={textContent?.cookieTablePurpose}
                    table={item?.table}
                  />
                ) : (
                  ""
                )}
              </TextSection>
            ))}
          </RightPage>
        </Content>
      </Container>
    </Main>
  );
};

export default IndexPage;
export const query = graphql`
  query MyQuery($language: String) {
    contentfulTextualContent(node_locale: { eq: $language }) {
      id
      cookieTitle
      cookieTableService
      cookieTablePurpose
      cookieTableName
    }
    contentfulPaginaCookie(
      node_locale: { eq: $language }
      pageId: { eq: "Istituzionale" }
    ) {
      paragraph {
        id
        name
        link
        sort
        description {
          description
        }
        table {
          id
          name
          service
          sort
          purpose {
            purpose
          }
        }
      }
    }
  }
`;
