import React, { Fragment } from "react";
import styled from "styled-components";
import { contentfulPaginaCookie } from "../../types/CookiesPage";

const NavMenu = styled.div`
  align-self: start;
  position: sticky;
  top: 160px;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const NavItem = styled.a`
  text-decoration: none;
  font-size: 16px;
  line-height: 19px;
  margin: 6px 6px 6px 0;
  color: #16435f;
  padding: 6px;
  border-radius: 5px;
  &:hover {
    font-weight: bold;
    background-color: rgba(204, 204, 204, 0.5);
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;

interface SideBarProps {
  navItems?: contentfulPaginaCookie;
}

const SideBar: React.FC<SideBarProps> = ({ navItems }) => {
  return (
    <NavMenu>
      <Nav>
        {navItems?.paragraph?.map((item, i) => (
          <Fragment key={i}>
            {item.name ? (
              <NavItem href={`#${item?.link}`}>{item?.name}</NavItem>
            ) : (
              ""
            )}
          </Fragment>
        ))}
      </Nav>
    </NavMenu>
  );
};

export default SideBar;
